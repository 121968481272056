sticky<template>
  <TepmplateBlock
    content-class="mytechnique-wrap mt-4"
    mod-class
    title-page="Офис фирмы"
  >
    <vuescroll>
      <div class="scroll-container">
        <div class="tip-anchor"></div>
        <div class="office-info">
          <form @submit.prevent="onSubmit" action="">
            <div
              v-if="isIntroPassed === 'false'"
              class="intro-tip p-2 w-75 text-center"
            >
              Заполните красные обязательные поля и нажмите кнопку "Сохранить"
            </div>

            <h2 class="office-info__main-title">
              Реквизиты офиса
            </h2>
            <br />

            <Input
              v-model="office.office_name"
              label="Название офиса"
              :required="true"
              :is-need-validation="true"
              :class="{ 'over-top': isIntroPassed === 'false' }"
              :newClass="isIntroPassed === 'false' ? 'over-top' : ''"
            />

            <Input
              v-model="office.office_city"
              label="Город"
              :required="true"
              :is-need-validation="true"
              :class="{ 'over-top': isIntroPassed === 'false' }"
              :newClass="isIntroPassed === 'false' ? 'over-top' : ''"
            />

            <Input
              v-model="office.office_index"
              label="Индекс"
              :class="{ 'over-top': isIntroPassed === 'false' }"
              :newClass="isIntroPassed === 'false' ? 'over-top' : ''"
            />

            <Input
              v-model="office.office_street"
              label="Улица"
              :required="true"
              :is-need-validation="true"
              :class="{ 'over-top': isIntroPassed === 'false' }"
              :newClass="isIntroPassed === 'false' ? 'over-top' : ''"
            />

            <Input
              v-model="office.office_home_num"
              label="Номер дома"
              :required="true"
              :is-need-validation="true"
              :class="{ 'over-top': isIntroPassed === 'false' }"
              :newClass="isIntroPassed === 'false' ? 'over-top' : ''"
            />

            <Input
              v-model="office.office_struct_num"
              label="Номер корпуса"
              :class="{ 'over-top': isIntroPassed === 'false' }"
              :newClass="isIntroPassed === 'false' ? 'over-top' : ''"
            />

            <Input
              v-model="office.office_enter_num"
              label="Подьезд"
              :class="{ 'over-top': isIntroPassed === 'false' }"
              :newClass="isIntroPassed === 'false' ? 'over-top' : ''"
            />

            <Input
              v-model="office.office_floor"
              label="Этаж"
              :class="{ 'over-top': isIntroPassed === 'false' }"
              :newClass="isIntroPassed === 'false' ? 'over-top' : ''"
            />

            <div class="form-group">
              <label
                class="form-group__label phone-label"
                :class="isIntroPassed === 'false' ? 'over-top' : ''"
                >Телефон&nbsp;<span style="color: red">*</span></label
              >
              <input
                type="text"
                class="form-control phone"
                :class="{
                  'over-top': isIntroPassed === 'false',
                  invalid: phoneNumber.length < 9,
                  valid: phoneNumber.length > 9
                }"
                v-model="phoneNumber"
                v-phone
              />
            </div>

            <div class="office-info__btn-group justify-content-center">
              <!-- <router-link
                type="button"
                to="/admin-profile"
                class="btn btn-primary office-info__btn-prev"
                :class="{ 'over-top': isIntroPassed === 'false' }"
                v-if="isIntroPassed === 'true'"
              >
                Назад
              </router-link> -->

              <span
                class="btn btn-primary office-info__btn-prev"
                :class="{ 'over-top': isIntroPassed === 'false' }"
                v-if="isIntroPassed === 'true'"
                @click="onGoBack"
              >
                Назад
              </span>

              <button
                type="submit"
                class="btn btn-second"
                :class="{ 'over-top': isIntroPassed === 'false' }"
              >
                Сохранить
              </button>
            </div>
          </form>
        </div>
      </div>
    </vuescroll>
    <div v-if="isIntroPassed === 'false'" class="dark-intro-background">
      <div class="intro-tip-wrapper">
        <!-- <div class="intro-target"></div> -->
        <div class="intro-tip p-2 w-25 text-center">
          Заполните красные обязательные поля и нажмите кнопку "Сохранить"
        </div>
      </div>
    </div>
  </TepmplateBlock>
</template>

<script>
import TepmplateBlock from "../../components/TepmplateBlock";
import Input from "../../components/Form/FormElement/Input";
import RequestManager from "../../function/request/RequestManager";
import { mapActions, mapGetters } from "vuex";
import { SET_NEW_OFFICE } from "../../store/types";
import vuescroll from "vuescroll";
import { doPoseTip } from "@/utils";
import { OfficeApi } from "../../function/request/apiV2";

export default {
  name: "OfficeInfo",
  components: { Input, TepmplateBlock, vuescroll },
  computed: {
    ...mapGetters(["getErrors"]),
    phone() {
      return this.phoneCode + this.phoneNumber;
    }
  },
  async created() {
    this.isIntroPassed = localStorage.getItem("isIntroPassed");
    console.log("this.isIntroPassed" + this.isIntroPassed);
    if (this.$route.params.id > 0) {
      this.editOfficeId = this.$route.params.id;
      console.log("Office param = ", this.editOfficeId);

      const itemsOffices = await this.officeApi.api.getOffice(
        this.editOfficeId
      );
      this.officesInfo = itemsOffices;

      // assign data to web form office
      this.office.office_name = this.officesInfo.name;
      this.office.office_city = this.officesInfo.city;
      this.office.office_index = this.officesInfo.index;
      this.office.office_street = this.officesInfo.street;
      this.office.office_home_num = this.officesInfo.homeNum;
      this.office.office_struct_num = this.officesInfo.structNum;
      this.office.office_enter_num = this.officesInfo.enterNum;
      this.office.office_floor = this.officesInfo.floor;
      this.phoneNumber = this.officesInfo.phone;
    }
  },
  mounted() {
    console.log("mounted");
    this.companyId = this.$route.params.companyId;
    /*
    RequestManager()
      .getCurrentUser()
      .then(data => {
        this.companyId = data.company;
      });
    */
    if (this.isIntroPassed === "false") {
      doPoseTip(".tip-anchor", "top");
    }
  },
  data() {
    return {
      editOfficeId: 0,
      officesInfo: {},
      officeApi: new OfficeApi(),
      phoneCode: "",
      phoneNumber: "",
      phoneOptions: [{ value: "+7", text: "+7" }],
      companyId: null, //	integer title: Компания
      office: {
        office_name: "", // Наименование офиса
        office_city: "", // Город
        office_index: "", //	Индекс
        office_street: "", // Улица
        office_home_num: "", // Номер дома
        office_struct_num: "", // Номер корпуса,
        office_enter_num: "", // Подъезд
        office_floor: "", // Этаж
        phone: ""
      },
      officeV2: {
        name: "", // Наименование офиса
        city: "", // Город
        index: "", //	Индекс
        street: "", // Улица
        homeNum: "", // Номер дома
        structNum: "", // Номер корпуса,
        enterТum: "", // Подъезд
        floor: "", // Этаж
        phone: ""
      },
      isIntroPassed: "true"
    };
  },
  methods: {
    ...mapActions({ SET_NEW_OFFICE }),
    updateCode(value) {
      this.phoneCode = value;
    },
    onGoBack() {
      this.$router.go(-1);
    },
    async onSubmit() {
      // if editOfficeId = 0, then we want to create new office
      if (this.editOfficeId == 0) {
        const data = {
          ...this.office,
          company: this.companyId,
          phone: this.phoneNumber
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll("-", "")
            .replaceAll(" ", ""), // this.phoneCode + this.phoneNumber,
          office_enter_num:
            this.office.office_enter_num.length === 0
              ? "0"
              : this.office.office_enter_num,
          office_floor:
            this.office.office_floor.length === 0
              ? "0"
              : this.office.office_floor
        };

        RequestManager()
          .addNewOffice(data)
          .then(response => {
            if (Object.keys(this.getErrors).length === 0) {
              this.SET_NEW_OFFICE(response);
              localStorage.setItem("isGeneralInfoExist", "true");
              localStorage.setItem("isAdminProfileFilled", "true");
              localStorage.setItem("isOfficeAdded", "true");
              console.log("this.isIntroPassed" + this.isIntroPassed);

              // if (this.isIntroPassed === "false") {
              //   this.$router.push({
              //     name: "ManagerProfile"
              //   });
              //   console.log("ManagerProfile");
              // } else {
              //   this.$router.push({
              //     name: "AdminProfile"
              //   });
              //   console.log("AdminProfile");
              // }
            } else {
              this.$bvToast.toast("Не заполнены обязательные поля", {
                variant: "danger",
                title: "Ошибка"
              });
            }
          });
      } // end creating new office
      else {
        // here we send request for edit office data
        this.officeV2.name = this.office.office_name;
        this.officeV2.city = this.office.office_city;
        this.officeV2.index = this.office.office_index;
        this.officeV2.street = this.office.office_street;
        this.officeV2.homeNum = this.office.office_home_num;
        this.officeV2.structNum = this.office.office_struct_num;
        this.officeV2.enterNum = this.office.office_enter_num;
        this.officeV2.floor = this.office.office_floor;
        this.officeV2.phone = this.office.phone;

        const data = {
          ...this.officeV2,
          phone: this.phoneNumber
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll("-", "")
            .replaceAll(" ", ""), // this.phoneCode + this.phoneNumber,
          enterNum:
            this.office.office_enter_num.length === 0
              ? "0"
              : this.office.office_enter_num,
          floor:
            this.office.office_floor.length === 0
              ? "0"
              : this.office.office_floor
        };
        console.log("data=", data);
        const result = await this.officeApi.api.editOffice(
          this.editOfficeId,
          data
        );
        console.log("result=", result);
      }
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.scroll-container {
  width: 500px !important;
  margin: 0 auto;
  overflow: hidden;
}
@media (min-width: 375px) and (max-width: 425px) {
  .scroll-container {
    width: 345px !important;
    margin: 0;
  }
}
@media (min-width: 320px) and (max-width: 374px) {
  .scroll-container {
    width: 305px !important;
    margin: 0;
  }
}
@media (min-width: 425px) and (max-width: 510px) {
  .scroll-container {
    width: 395px !important;
    margin: 0;
  }
}

.form-group__label {
  position: absolute;
  top: 5px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  color: var(--main-text-color);
}

.form-group__label.required:after {
  content: "*";
  color: red;
  font-size: 26px;
  position: absolute;
}

.office-info {
  padding: 38px 72px 36px;
  width: 100%;
  max-width: 498px;
  min-height: 672px;
  background: var(--main-card-color);
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
  border-radius: 6px;
  margin: 0 auto 10px;
  position: relative;
  z-index: 1001;
}

.office-info__btn-group {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 14px;
}

.office-info__select-group {
  position: relative;
  display: flex;
  align-items: center;
}

.office-info__btn {
  max-width: 100%;
}

.office-info__btn-prev {
  border: 1px solid #006b90;
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.01));
  margin-right: 7px;
}

.office-info__select {
  height: 66px;
  width: 75px;
  margin-right: 13px;
}

.form-group {
  width: 100%;
}

.phone-label {
  font-size: 14px;
  font-weight: 500;
  position: sticky !important;
}
.phone {
  background: var(--auth-input-bg);
  border: 1px solid var(--auth-input-bg);
  box-sizing: border-box;
  border-radius: 6px;
  min-height: 56px;
  color: var(--main-text-color);
}

.form-control.invalid {
  border: 1px rgb(245, 0, 0) solid;
}

.form-control.invalid:focus {
  box-shadow: 0 0 0 3px rgba(245, 0, 0, 0.2);
}

.form-control.valid {
  border: 1px rgb(40, 167, 69) solid;
}

.form-control.valid:focus {
  box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.2);
}

.office-info__main-title {
  margin-bottom: 28px;

  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.04em;

  color: var(--card-text-color);
}
</style>
